"use strict";
/*
ibb_dpb -> display block
ibb_dpn -> display none

//Pour les lecteurs d'écran et personnes naviguants au claviers, on zappe le système de carte, pas adapté
//la carte est cachée aux lecteurs d'écrans et un bouton spécifiqua aux lecteurs d'écran permet d'afficher toutes les agences
*/

document.addEventListener('DOMContentLoaded', function () {
  //par défaut la carte est cachée et toutes les agences sont affichées
  //On affiche la carte
  document.getElementById("ibb_agencies_cartographie").classList.remove("ibb_dpn"); //on cache la carte IDF

  document.querySelector(".ibb_agencies_card_idf").classList.add("ibb_dpn"); //on cache la section de détail des agences

  document.querySelector(".ibb_agencies_details").classList.add("ibb_dpn");
  document.querySelector(".ibb_agencies_details").setAttribute('aria-hidden', true); //on cache tous les détails d'agence

  document.querySelectorAll(".ibb_agencies_desc").forEach(function (target) {
    target.classList.add("ibb_dpn");
  });
  /* Inutile ci dessous ???*/

  var param_url = location.search.substring(1).split('&');
  var nb_param = param_url.length;

  for (var i = 0; i < nb_param; i++) {
    var param = param_url[i].split('=');

    if (param[0] == "agence") {
      return details(param[1]);
    }
  }
  /* Inutile ci dessus ???*/
  //gestion du clavier sur la carte


  document.querySelectorAll(".idb_agence").forEach(function (el) {
    var card = el;
    card.addEventListener("focus", function () {
      //on retire la classe focus à toutes les autres cartes
      document.querySelectorAll(".idb_agence.focus").forEach(function (el) {
        el.classList.remove("focus");
      }); //on ajoute la classe focus à cette carte

      card.classList.add("focus");
    });
  });
  document.querySelectorAll("#show-all-agencies,#ibb_agencies_details").forEach(function (el) {
    el.addEventListener("focus", function () {
      //on retire la classe focus à toutes les cartes
      document.querySelectorAll(".idb_agence.focus").forEach(function (el) {
        el.classList.remove("focus");
      });
    });
  }); //Pour les lecteurs d'écran et personnes naviguants au claviers, on zappe le système de carte, pas adapté
  //La fonction ci dessous permets dans ce cas d'afficher toutes les agences

  document.getElementById("show-all-agencies").addEventListener("click", function () {
    if (this.ariaExpanded == "false") {
      this.innerHTML = this.dataset.hideTxt;
      this.ariaExpanded = "true"; //on affiche la section de détail des agences

      document.querySelector(".ibb_agencies_details").classList.remove("ibb_dpn");
      document.querySelector(".ibb_agencies_details").setAttribute('aria-hidden', false); //on affiche tous les détails d'agence

      document.querySelectorAll(".ibb_agencies_desc").forEach(function (target) {
        target.classList.remove("ibb_dpn");
      });
      document.querySelector(".ibb_agencies_details").focus();
    } else {
      this.innerHTML = this.dataset.showTxt;
      this.ariaExpanded = "false"; //on cache la section de détail des agences

      document.querySelector(".ibb_agencies_details").classList.add("ibb_dpn");
      document.querySelector(".ibb_agencies_details").setAttribute('aria-hidden', true); //on cache tous les détails d'agence

      document.querySelectorAll(".ibb_agencies_desc").forEach(function (target) {
        target.classList.add("ibb_dpn");
      });
    }
  }); //Affiche du détail d'une agence

  document.querySelectorAll("#nos-agences button.title_js").forEach(function (el) {
    el.addEventListener("click", function () {
      var idDetails = this.dataset.details;
      console.log(idDetails); //on affiche la section de détail des agences

      document.querySelector(".ibb_agencies_details").classList.remove("ibb_dpn");
      document.querySelector(".ibb_agencies_details").setAttribute('aria-hidden', false); //On cache les détails de l'agence précédemment affiché

      document.querySelectorAll(".ibb_agencies_desc:not(.ibb_dpn)").forEach(function (el) {
        el.classList.add("ibb_dpn");
      }); //On affiche les détails de l'agence

      document.getElementById(idDetails).classList.remove("ibb_dpn");
      /*
            	var headerOffset = 0;
              var elementPosition = document.getElementById(idDetails).offsetTop;
              var offsetPosition = elementPosition - headerOffset;
      
              window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth"
              });*/

      document.getElementById("ibb_agencies_details").focus();
    });
  }); //Affiche des plans

  document.querySelectorAll("[data-plan-maps]").forEach(function (el) {
    var mapButton = el;
    mapButton.addEventListener("click", function () {
      mapButton.setAttribute('aria-expanded', true);
      var mapContainer = mapButton.parentNode;
      mapContainer.querySelector(".ibb_agencies_iframe_plan").setAttribute('aria-hidden', false);

      if (!mapContainer.querySelector("iframe")) {
        var iframeElement = document.createElement("iframe");

        iframeElement.onload = function () {
          mapContainer.querySelector(".ibb_agencies_iframe_plan_loading").classList.add("ibb_dpn");
        };

        iframeElement.src = mapButton.dataset.planMaps;
        iframeElement.frameborder = "0";
        mapContainer.querySelector(".ibb_agencies_iframe_plan").appendChild(iframeElement);
      }
    });
  }); //fermeture des plans

  document.querySelectorAll(".ibb_agencies_iframe_plan_close").forEach(function (el) {
    var closeButton = el;
    closeButton.addEventListener("click", function () {
      console.log(closeButton.parentNode); //on cache le parent (ibb_agencies_iframe_plan)

      closeButton.parentNode.setAttribute('aria-hidden', true); //on régle les attributs aria du déclencheur

      closeButton.parentNode.parentNode.querySelector("[data-plan-maps]").setAttribute('aria-expanded', false);
    });
  }); //Bouton pour passer de la carte de France à la carte d'IDF

  document.querySelectorAll("button.switch-cards").forEach(function (el) {
    el.addEventListener("click", function () {
      if (document.getElementById("card_idf").classList.contains("ibb_dpn")) {
        document.getElementById("card_idf").classList.remove("ibb_dpn");
        document.getElementById("card_france").classList.add("ibb_dpn");
      } else {
        document.getElementById("card_idf").classList.add("ibb_dpn");
        document.getElementById("card_france").classList.remove("ibb_dpn");
      } //on cache la section de détail des agences


      document.querySelector(".ibb_agencies_details").classList.add("ibb_dpn");
      document.querySelector(".ibb_agencies_details").setAttribute('aria-hidden', true); //on cache tous les détails d'agence

      document.querySelectorAll(".ibb_agencies_desc").forEach(function (target) {
        target.classList.add("ibb_dpn");
      }); //on remets le focus avant la carte

      document.getElementById("ibb_agencies_cartographie").focus();
    });
  });
});